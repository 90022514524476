import React, { useState } from "react";
import { Helmet } from "react-helmet";
import MyRouter from "routers/index";

import chatbotlogo from './images/logos/child-chatbot.png'
import "./styles/chatbot-iframe.scss";

function App() {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <>
      <Helmet>
        <title>TekBrainer</title>
      </Helmet>
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <MyRouter />
      </div>

      <button className="chat-toggle-button bg-gradient-to-r from-cyan-500 to-blue-500" onClick={toggleChat}>
        {isChatOpen ? '×' : '💬'}
      </button>
      <div className={`chat-container ${isChatOpen ? '' : 'hidden'}`}>
        


          <div className="chat-header text-gray-200 bg-gradient-to-r from-cyan-500 to-blue-500 flex items-center justify-between" onClick={toggleChat}>
                    <span className="text-xl">
                    <img src={chatbotlogo} width={35} height={35} />
                    </span>
                    <span className='text-white font-bold text-md'>
                        Jehnzky Chatbot
                    </span>
                </div>
        <iframe
          src="https://jehnzky-chatbot.vercel.app/"
          className="chat-iframe"
          title="Chatbot"
        ></iframe>
      </div>
    </>

  );
}

export default App;
