import React, { FC, useEffect, useState } from "react";
import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import { Link, useLocation } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { getRelatedPosts, supabase } from "services/supabase";
import { useAppSelector } from "store/hooks";
import { tagSelector } from "store/reducers/tagReducer";
import LikeSaveBtns from "components/LikeSaveBtns";
import BtnEditPost from "components/BtnEditPost";

const BlogContent: FC<any> = () => {
  const [content, setContent] = useState<any>({})
  const [isLoading, setLoading] = useState<boolean>(true)
  const [image, setImage] = useState('')
  const [authorImgUrl, setAuthorImgUrl] = useState('')
  const [relatedPosts, setRelatedPosts] = useState<any>([])
  const rawHtml = content?.body || ''
  const location = useLocation()

  const tagState = useAppSelector(tagSelector)
  const sessionData = localStorage.getItem('sb-vdyxajfjdaephpaelveo-auth-token')
  
  useEffect(() => {
 
    fetchPost(location.state?.id)

  }, [])

  const fetchPost = async (postId) => {
    const { data: post, error } = await supabase
        .from('posts')
        .select(`
            *, 
            authors ( id, username, firstname, lastname, socialMedia ),
            topics (name)
          `)
        .eq('id', postId)

      if (post) {
        setContent(post[0])
        setImage(`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/blog-photos/${post[0].thumbnail}`)
        setAuthorImgUrl(`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/author-photos/${post[0]?.authorId}.jpg`)
        setLoading(false)
        const relatedPostsResponse = await getRelatedPosts(postId, post[0]?.tags)

        setRelatedPosts(relatedPostsResponse)
      }
  }

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          {/* <Badge href="##" color="purple" name={content.topics?.name} /> */}
          
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            {content?.title || ''}
          </h1>
          <span className="block text-base text-neutral-400 md:text-lg dark:text-neutral-400 pb-1">
            {content.subheading}
          </span>



          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                imgUrl={authorImgUrl}
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="/">
                    {content.authors?.firstname} {content.authors?.lastname}
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {content?.created_at?.substring(0, 10) || ''}
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {content.readingTime} min read
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
            {!sessionData && <LikeSaveBtns postId={content.id} currentCount={content.like.count} />}
            {sessionData && <BtnEditPost postId={location.state.id} postTitle={location.pathname.split('/')[2]} />}
              {/* <SocialsList socials={content?.authors?.socialMedia} /> */}
            </div>
          </div>
        </div>
      </header>
    );
  };


  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >

        <div dangerouslySetInnerHTML={{ __html: rawHtml }} />
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        {content.tags?.map(t => {
          let tag = tagState.tags.find(ts => ts.id === t)
            if (tag) {
              return (
                <a
                  className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                  href="##"
                >
                  #{tag.name}
                </a>
              );
            } else {
              return null
            }

        })}
        {/* <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Garden
        </a> */}
        {/* <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Jewelry
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Tools
        </a> */}
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar imgUrl={authorImgUrl} sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">{`${content.authors?.firstname} ${content.authors?.lastname}`}</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              {/* There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a> */}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Responses (14)
        </h3>
        <form className="nc-SingleCommentForm mt-5">
          <Textarea />
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Submit</ButtonPrimary>
            <ButtonSecondary>Cancel</ButtonSecondary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (post: any) => {
    return (
      
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
        onClick={() => fetchPost(post.id)}
      >
        {/* <Link  to={`/${post.author?.firstname}/${post.title.toLocaleLowerCase().trim().replaceAll(" ", "-")}`} state={{ id: post.id }} /> */}
        {!isLoading && <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/blog-photos/${post?.thumbnail}`}
        />}
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name={post.posts_tags[0]?.tags?.name} />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author?.firstname} {post.author?.lastname}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        {/* <Link to={post.href} /> */}
      </div>

    );
  };

  if (isLoading) {
    return (
      <header className="container rounded-xl mt-5">
        <div className="max-w-screen-md mx-auto space-y-5">

          <div className="space-y-6">
            <div className="animate-pulse space-y-2">
              <div className="h-4 w-1/3 rounded-full bg-gray-200 dark:bg-gray-700" />
            </div>
            <div className="animate-pulse h-10 w-full rounded-full bg-gray-200 dark:bg-gray-700" />
          </div>
          <div className="animate-pulse space-y-4">
            <div className="h-8 w-1/2 rounded-full bg-gray-200 dark:bg-gray-700" />
            <div className="h-48 w-1/2 rounded-lg bg-gray-200 dark:bg-gray-700" />
          </div>
          <div className="animate-pulse mt-6">
            <div className="h-48 w-full rounded-lg bg-gray-200 dark:bg-gray-700" />
          </div>


          <div className="w-full h-lvh border-b border-neutral-100 dark:border-neutral-800"></div>

        </div>
      </header>
    );
  }

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
     
    
      {renderHeader()}

      <div className="nc-SingleContent container space-y-10">

        {content.postType === 'standard' && <NcImage
          className="w-full rounded-xl"
          containerClassName="container my-10 sm:my-12 "
          src={image}
        />}

        {content.postType === 'video' && <div
          className="group aspect-w-16 aspect-h-16 sm:aspect-h-9 bg-neutral-800 rounded-3xl overflow-hidden border-4 border-white dark:border-neutral-900 sm:rounded-[50px] sm:border-[10px] will-change-transform"
          title={content.title}
        >
          <iframe
            src={`https://vdyxajfjdaephpaelveo.supabase.co/storage/v1/object/public/blog-videos/hydrogen_energy_explainer_no_text.mp4`}
            title={content.title}
            // className="w-full rounded-xl"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            
          ></iframe>

        </div>}
       

        {renderContent()}
        {renderTags()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>

        {renderAuthor()}
        {/* {renderCommentForm()} */}
        {/* {renderCommentLists()} */}
      </div>
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Related posts</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {/*  */}
            {relatedPosts?.map(renderPostRelated)}
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogContent;
