import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";

interface Tagstate {
    isLoading: boolean;
    error: string | undefined;
    tags: Array<any>;
}

const initialState: Tagstate = {
    isLoading: false,
    error: undefined,
    tags: [],
}

export const fetchTagsResult: any = createAsyncThunk(
    'cities/all',
    async () => {
        // return await fetchTags()
    }
)

export const tagSlice = createSlice({
    name: 'tag',
    initialState,
    reducers: {
        setTags: (state, action) => {
            state.tags = action.payload
        }
    },
    extraReducers: builder => {

    }
})

export const tagSelector = (state: RootState) => state.tagReducer;

export const { setTags } = tagSlice.actions

export const tagReducer = tagSlice.reducer