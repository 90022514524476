import React, { ReactNode } from "react";
// import { imageGallery as listingStayImageGallery } from "./listing-stay-detail/constant";
// import { imageGallery as listingCarImageGallery } from "./listing-car-detail/constant";
// import { imageGallery as listingExperienceImageGallery } from "./listing-experiences-detail/constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import MobileFooterSticky from "containers/ListingDetailPage/(components)/MobileFooterSticky";
// import MobileFooterSticky from "./(components)/MobileFooterSticky";
// import BookingProcess from "components/Stepper/BookingProcess";

const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${thisPathname}/?${params.toString()}`);
  };

  const getImageGalleryListing = () => {
    // if (thisPathname?.includes("/listing-stay-detail")) {
    //   return listingStayImageGallery;
    // }
    // if (thisPathname?.includes("/listing-car-detail")) {
    //   return listingCarImageGallery;
    // }
    // if (thisPathname?.includes("/listing-experiences-detail")) {
    //   return listingExperienceImageGallery;
    // }

    return [];
  };

  return (
    <div className="ListingDetailPage">
        <ListingImageGallery
            isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
            onClose={handleCloseModalImageGallery}
            images={getImageGalleryListing()}
        />

        <div className="container ListingDetailPage__content">
            <div className="mt-8">
                {/* <BookingProcess /> */}
            </div>
           
            {children}
        </div>

    </div>
  );
};

export default DetailPagetLayout;
