import React, { FC, useEffect, useState, Suspense, useMemo } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import Heading from "components/Heading/Heading";
import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
// import Pagination from "shared/Pagination/Pagination";
import Pagination from "./Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import WidgetTags from "./WidgetTags";
import WidgetCategories from "./WidgetCategories";
import WidgetPosts from "./WidgetPosts";

import { parseInt } from "lodash";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { tagSelector } from "store/reducers/tagReducer";
import { getPosts } from "services/supabase";
import { postSelector, setPosts } from "store/reducers/postReducer";

const Card3 = React.lazy(() => import('./Card3'));

export interface SectionLatestPostsProps {
  // posts?: PostDataType[];
  tags?: any[];
  topics?: any[];
  popularPosts?: any[];
  className?: string;
  postCardName?: "card3";
}

const SectionLatestPosts: FC<SectionLatestPostsProps> = ({
  // posts,
  tags,
  topics,
  popularPosts,
  postCardName = "card3",
  className = "",
}) => {

  const tagState = useAppSelector(tagSelector)
  const postState = useAppSelector(postSelector)
  const dispatch = useAppDispatch()

  // const [posts, setPosts] = useState<any[]>([])
  let posts = useState<any[]>([])
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [postsPerPage] = useState(10);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = postState.posts?.slice(indexOfFirstPost, indexOfLastPost);

  const pageNumberLimit = 5;
  const [maxPageLimit, setMaxPageLimit] = useState(5)
  const [minPageLimit, setMinPageLimit] = useState(0)
  const [loading, setLoading] = useState(true);

  const from = (currentPage - 1) * postsPerPage;
  const to = from + postsPerPage - 1;

  useEffect(() => {

    fetchPosts()
    
  }, [dispatch, setPosts])

  const fetchPosts = async () => {
    const response = await getPosts(from, to)

    if (response) {
      if(postState.posts) {
        const newPosts = Array.from(new Set([...postState.posts, ...response]));
        const filtered = Array.from(new Map(newPosts.map(item => [item.id, item])).values());
        dispatch(setPosts(filtered));
      } else {
        dispatch(setPosts(response));
      }
      setCurrentPage(currentPage + 1);
      setLoading(false)
    }

    if (response.length < 10) {
      setHasMore(false)
    }

  }

  const handlePagination = (e: any) => {
    setCurrentPage(parseInt(e.target.textContent));
  }

  const previousPage = () => {

    if (currentPage === 1) return;

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }

    if (currentPage !== 1) {
      setCurrentPage(prev => prev - 1);
    }
  }

  const nextPage = () => {

    if (postState.posts && indexOfLastPost > postState.posts.length) return;

    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    setCurrentPage(prev => prev + 1);
  }

  const renderCard = (post: any) => {

    switch (postCardName) {
      case "card3":
        return (

          <Card3 key={post.id} className="" post={post} tags={tagState.tags} />

          // <Card3 key={post.id} className="" post={post} tags={tagState.tags} />
        );
      default:
        return null;
    }
  };

  const renderLoader = () => {
    return (
      <div
        className={`nc-Card3 relative flex-1 flex-row sm:flex-row sm:items-center rounded-[40px] group ${className}`}
        data-nc-id="Card3"
      >
        {Array.from({ length: 2 }).map((_, ind) => (
          <div key={ind +3}>
            <div className="flex flex-col flex-grow mt-5 gap-3">
              <div className="space-y-5 mb-4">
                <div className="animate-pulse space-y-2">

                  <div className="flex flex-wrap gap-2">
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div key={index} className="h-6 w-20 rounded-full bg-gray-200 dark:bg-gray-700" />
                    ))}
                  </div>
                </div>
                <div>
                  <h2
                    className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 text-xl`}
                  >
                    {/* <div className="animate-pulse h-10 w-full rounded-full bg-gray-200 dark:bg-gray-700  mt-3" /> */}
                  </h2>
                  <div className="hidden sm:block sm:mt-2">
                    <span className="text-neutral-500 dark:text-neutral-400 text-base line-clamp-2">
                      <div className="animate-pulse h-15 w-full rounded-full bg-gray-200 dark:bg-gray-700  mt-3" />
                    </span>
                  </div>
                </div>

                <div className="h-4 w-1/3 rounded-full bg-gray-200 dark:bg-gray-700" />
              </div>
            </div>

            <div
              className={`block flex-shrink-0 sm:w-56 sm:ml-6 rounded overflow-hidden mb-5 sm:mb-0 mt-3`}
            >
              <a href='#'>
                <div className="animate-pulse mt-6">
                  <div className="h-48 w-full rounded-md bg-gray-200 dark:bg-gray-700" />
                </div>

              </a>
            </div>

          </div>
        ))}
      </div>
    )
  }

  const renderWidgetLoader = () => {
    return (
      <div
        className={`nc-WidgetTags rounded-3xl overflow-hidden ${className}`}
        data-nc-id="WidgetTags"
      >
        <div className="flex flex-wrap p-4 xl:p-5">

          <div className="space-y-6">
            <div className="animate-pulse space-y-2">
              <div className="h-4 w-1/3 rounded-full bg-gray-200 dark:bg-gray-700" />
              <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
                {Array.from({ length: 8 }).map((_, index) => (
                  <div key={index} className="h-8 rounded-full bg-gray-200 dark:bg-gray-700" />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (!postState.posts) return (<></>);

  if (loading) return renderLoader();


  return (
    <div className={`nc-SectionLatestPosts relative ${className}`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full  xl:pr-5">
          {/* <Heading>Latest Articles 🎈</Heading> */}
          <div className={`grid gap-6 md:gap-8 grid-cols-1`}>
            {/* {currentPosts?.map((post) => renderCard(post))} */}
            {/* {posts?.map((post) => renderCard(post))} */}

            <Suspense fallback={renderLoader()}>
              <InfiniteScroll
                dataLength={postState.posts.length}
                next={fetchPosts}
                hasMore={hasMore}
                loader={renderLoader()}
                // endMessage={<p className="mt-12 text-md font-medium text-gray-500">No more posts 😜</p>}

                refreshFunction={fetchPosts}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={
                  <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                }
                releaseToRefreshContent={
                  <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                }
              >
                {postState.posts.map((post) => (
                  <Card3 key={post.id} className="" post={post} tags={tagState.tags} />
                ))}
              </InfiniteScroll>
            </Suspense>


          </div>
          {/* <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center"> */}

            {/* <Pagination
            length={posts.length}
            postsPerPage={postsPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
            previousPage={previousPage}
            nextPage={nextPage}
            maxPageLimit={maxPageLimit}
            minPageLimit={minPageLimit}
          /> */}
            {/* <ButtonPrimary>Show me more</ButtonPrimary> */}

          {/* </div> */}
        </div>


      </div>
    </div>
  );
};

export default SectionLatestPosts;
