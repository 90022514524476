import React, { FC, useEffect, useState } from "react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
// import { Amenities_demos, includes_demo, PHOTOS } from "./constants";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
// import SectionDateRange from "./SectionDateRange";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "./GuestDetailsLayout";
import { getTags, getTopics } from "services/supabase";
import { setTags, tagSelector } from "store/reducers/tagReducer";
import { useAppDispatch, useAppSelector } from "store/hooks";
import SectionLatestPosts from "./SectionLatestPosts";
import WidgetCategories from "./WidgetCategories";
import WidgetTags from "./WidgetTags";
// import RentalCarDatesRangeInput from "./RentalCarDatesRangeInput";
// import FormEntry from "./FormEntry";
// import SectionGridCardGuest from "./SectionGridCardGuest";
// import BookingProcess from "components/Stepper/BookingProcess";
export interface gdProps {
    // tags?: any[];
    // topics?: any[];
  }


const GuestDetailsPage: FC<gdProps>  = () => {

    // const [tags, setTags] = useState<any[]>([])
    const [loading, setLoading] = useState(true);
    const [topics, setTopics] = useState<any[]>([])
    const dispatch = useAppDispatch()
    const tagState = useAppSelector(tagSelector)
    useEffect(() => {
        const fetchData = async () => {
        //   const postsResponse = await getPosts(1, 10)
    
          const tagsResponse = await getTags()
    
        //   const topicsResponse = await getTopics()
    
        //   const popularPostsResponse = await getPopularPosts()
    
        //   if (postsResponse) {
        //     // setPosts(postsResponse)
        //     setLoading(false)
        //   }
    
          if (tagsResponse) {
            dispatch(setTags(tagsResponse))
            setLoading(false)
          }
    
        //   if (topicsResponse) setTopics(topicsResponse);
    
        //   if (popularPostsResponse) setPopularPosts(popularPostsResponse)
    
        }
    
        fetchData()
      }, [])
    

  const thisPathname = useLocation().pathname;
  const router = useNavigate();


  return (
    <div className={` nc-ListingCarDetailPage `}>
      {/* SINGLE HEADER */}
    
      {/* MAIn */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
          <SectionLatestPosts tags={tagState.tags} className="py-12 lg:py-12" />
 
          <div className="block lg:hidden"><WidgetCategories categories={topics} /></div>

        </div>

        {/* SIDEBAR */}
        <div className="block flex-grow mt-14 lg:mt-0 w-1/4">
          {/* {renderSidebarDetail()} */}
          <div className="hidden lg:block mt-10 sticky top-28">
          {/* {loading && renderWidgetLoader()} */}
          {!loading && <WidgetTags tags={tagState.tags} />}
          </div>
        </div>
      </main>
    </div>
  );
};

export default function GuestDetailPage() {
  return (
    <DetailPagetLayout>
      <GuestDetailsPage />
    </DetailPagetLayout>
  );
}

// Header3
// HeroSearchFormSmall
// StaySearchForm