import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Jehnsen Enrique`,
    job: "Founder of TekBrainer, Content Creator & AI Enthusiast",
    avatar:
      "https://vdyxajfjdaephpaelveo.supabase.co/storage/v1/object/public/author-photos/f1557942-25f3-4567-a9ff-855cc24a84ea.jpg",
  }
];

const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading
        desc="I started this blog to share the wonders of web development and AI with everyone, from experts to beginners. 
        Here, we will explore new trends, tackle tech challenges, and learn together.
        You are welcome to join our community. Let’s dive into the future of technology together!"
      >
        ⛱ Message from the founder
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm">
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover"
              src={item.avatar}
            />
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.job}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFounder;
