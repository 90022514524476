import { FC, useEffect, useState } from "react";
import TextEditor from "./TextEditor";
import { createPost } from "services/supabase";

import FormItem from "containers/PageAddListing1/FormItem";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import KoalaEditor from "./KoalaEditor";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HtmlEditor from "./HtmlEditor";
import { useAppSelector } from "store/hooks";
import { postSelector } from "store/reducers/postReducer";
import FileUpload from "./FileUpload";
import { estimateReadingTime, formatDate } from "utils/helpers";

import { uploadFile } from "services/supabase";
import Editor from "./Editor";
import TagDropdown from "./TagDropdown";

const CreatePost: FC<any> = () => {
    const [content, setContent] = useState("")
    const [title, setTitle] = useState("")
    const [subHeading, setSubHeading] = useState("")
    const [uploading, setUploading] = useState(false)

    const postState = useAppSelector(postSelector)

    const submitPost: any = async (data: any) => {

        const result = await createPost({
            title: title,
            subheading: subHeading,
            body: postState.postBody,
            thumbnail: postState.filename,
            categoriesId: 1,
            postType: 'standard',
            authorId: "f1557942-25f3-4567-a9ff-855cc24a84ea",
            date: formatDate(new Date()),
            commentCount: 0,
            viewCount: 0,
            readingTime: estimateReadingTime(postState.postBody.length),
            bookmark: { "count": 0, "isBookmarked": false },
            like: { "count": 0, "isLiked": false }
        })

        const uploadResponse = await handleFileUPload()

        return result

    }

    const handleModelChange = (editorContent: any) => {
        const editorContentx: any = document.querySelector('.ContentEditable__root')?.innerHTML

        setContent(editorContentx)
    }

    const handleTitleChange = (e: any) => {
        setTitle(e.target.value)
    }

    const handleSubHeadingChange = (e: any) => {
        setSubHeading(e.target.value)
    }

    const handleFileUPload = async () => {
        try {
            const file = postState.fileObject
            setUploading(true);
            if (!file) return;

            // const fileExt = file.name.split('.').pop();
            const fileName = `${file.name}`;
            const filePath = `${fileName}`;

            const response = await uploadFile(filePath, file);

            console.log(response)
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Error uploading file!');
        } finally {
            setUploading(false);
        }
    };

    const [editorData, setEditorData] = useState(null);

    const handleEditorChange = (data) => {
        setEditorData(data);
    };

    // tagdropdown
    const [selectedTags, setSelectedTags] = useState([]);
    const options = ['JavaScript', 'React', 'CSS', 'Tailwind', 'Node.js'];

    return (

        <CommonLayout>

            <h2 className="text-xl font-semibold mb-5">Create New</h2>
            <div className="w-24 border-b border-neutral-200 dark:border-neutral-700"></div>
            {/* FORM */}
            <div className="space-y-8 mt-5">
                {/* ITEM */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-4xl">
                    <div className="space-y-2">
                        <FormItem label="Title" desc="" >
                            <Input placeholder="title of the article" onChange={handleTitleChange} />
                        </FormItem>
                        <FormItem label="Sub Heading" desc="" >
                            <Input placeholder="create a catchy introduction to your article" onChange={handleSubHeadingChange} />
                        </FormItem>
                    </div>
                    <div className="grid gap-4">
                        <FileUpload onHandleUpload={uploadFile} isUploading={uploading} />
                    </div>
                </div>

                {/* <FormItem label="Category" desc="" >
                    <Select>
                        <option value="Engineering">Engineering</option>
                        <option value="Architecture">Architecture</option>
                        <option value="AI">Artificial Intelligence</option>
                    </Select>
                </FormItem> */}

                {/* <KoalaEditor handleModelChange={handleModelChange} /> */}

                {/* <TextEditor onHandleChange={handleModelChange} /> */}
                <TextEditor />
                
            </div>

            <div className="mt-10">

                <div className="flex justify-end space-x-5">
                    <ButtonSecondary>Reset</ButtonSecondary>
                    <ButtonPrimary onClick={submitPost}> Submit </ButtonPrimary>
                </div>
            </div>

            <div className="w-full max-w-md mx-auto">
                <h1 className="text-xl font-bold mb-4">Select Tags</h1>
                <TagDropdown
                    options={options}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                />
                <div className="mt-4">
                    <h2 className="text-lg font-semibold">Selected Tags:</h2>
                    <ul className="list-disc list-inside">
                        {selectedTags.map((tag) => (
                            <li key={tag}>{tag}</li>
                        ))}
                    </ul>
                </div>
            </div>


        {/* <h2>Test Editor JS</h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <Editor onChange={handleEditorChange} initialData={editorData} /> */}
        
        </CommonLayout>
    );
};

export default CreatePost;
