
import { createClient } from '@supabase/supabase-js'
// import CryptoJS from 'crypto-js';

const supabaseUrl: any = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey: any = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseKey)

// try to encrypt data ----
// const decryptData = (encryptedData, secretKey) => {
//     const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
//     return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//     // return bytes.toString(CryptoJS.enc.Utf8)
// };
// Function to encrypt data
// const encryptData = (data, secretKey) => {
//     return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
// };

// Example data to be encrypted
// const data = {
//     username: 'testuser',
//     password: 'mypassword',
// };

// const secretKey = 'fuck-me-if-you-want'; // Use a strong, unique key
// const encryptedData = encryptData(data, secretKey);
// encrypt data---------


export const getPosts = async (from, to) => {
    try {
        const { data: posts, error } = await supabase
            .from('posts')
            .select(`*, authors ( id, firstname, lastname, thumbnail, socialMedia )`)
            .range(from, to)
            .order('created_at', { ascending: false })

        // if (error) {
        //     console.error('Error fetching posts:', error);
        //     return { data: [], error };
        // } 
        
         // try to implement encryption
        // const encryptedData = encryptData(data, secretKey);
        // console.log("encryptedData", encryptedData)
        // const deryptedData = decryptData(encryptedData, secretKey);
        // console.log("deryptedData", deryptedData)
       
        // posts.forEach((post) => {
        //     const decryptedData = decryptData(post.title, secretKey + post.id);
        //     console.log('Decrypted Data:', decryptedData);
        // });

        return posts
        
    } catch (e: any) {
        console.log(e)
    }
}

export const getPostContent = async (id: number) => {
    try {
        const { data: post, error } = await supabase
            .from('posts')
            .select('id')

        return post
    } catch (e: any) {
        console.log(e)
    }
}

export const getTopics = async () => {
    try {
        const { data: topics, error } = await supabase.from('topics').select('*')

        return topics
    } catch (error) {
        console.log(error)
    }
}

export const getPopularPosts = async () => {
    try {
        const { data: posts, error } = await supabase.from('posts').select('*').gte('viewCount', 30).range(0, 8)

        return posts
    } catch (error) {
        console.log(error)
    }
}

export const getTags = async () => {
    try {
        const { data: tags, error } = await supabase.from('tags').select('*')

        return tags
    } catch (error) {
        console.log(error)
    }
}

export const createPost = async (post: any) => {
    try {
        const { 
            title, 
            subheading, 
            body, 
            thumbnail, 
            date, 
            authorId, 
            categoriesId, 
            postType,
            commentCount, 
            viewCount, 
            readingTime, 
            bookmark, 
            like 
        } = post

        const { data: response, error } = await supabase

            .from('posts')
            .insert({
                title,
                subheading,
                body,
                thumbnail,
                date,
                authorId,
                categoriesId,
                postType,
                commentCount,
                viewCount,
                readingTime,
                bookmark,
                like
            })
            .select()

        return response

    } catch (error) {
        console.log(error)
    }
}

export const uploadFile = async (filepath, file) => {
    const { data, error } = await supabase.storage.from('blog-photos').upload(filepath, file, { upsert: true })

    if (error) throw error;

    return data
}

export const getRelatedPosts = async (currentPostId, tagIds) => {
    try {        

        const { data, error } = await supabase
            .from('posts')
            .select('id, title, thumbnail, authors ( id, firstname, lastname), posts_tags!inner(*, tags (name))')
            .in('posts_tags.tagId', tagIds)
            .neq('id', currentPostId)
            .limit(4)
            if (error) return error;
        return data
    } catch (e: any) {
        console.log(e)
    }
}

export const getPostsByTags = async (tagId) => {
    try {
        const { data: posts, error } = await supabase
            .from('posts')
            .select('id, title, subheading, thumbnail, authors ( id, firstname, lastname), created_at, posts_tags!inner(*, tags (name))')
            .in('posts_tags.tagId', [tagId])
            .order('created_at', { ascending: false })
            .limit(4)

        return posts
        
    } catch (e: any) {
        console.log(e)
    }
}

export const addLikeToPost = async (postId, currentCount) => {
    try {
        
        const { data: response, error } = await supabase
        .from('posts')
        .update({ like: { count: currentCount + 1, isLiked: true } })
        .eq('id', postId)
        .select('*')

        return response
                
    } catch (err) {
        return err
    }
}

export const searchPosts = async (searchTerm) => {
    const { data, error } = await supabase
      .from('posts')
      .select('*')
      .ilike('title', `%${searchTerm}%`); // Assuming you want to search in the 'title' field
  
    if (error) {
      console.error("Error searching posts:", error);
      return [];
    }
  
    return data;
  };