
import { configureStore } from '@reduxjs/toolkit';

import { postReducer } from './reducers/postReducer';
import { sessionReducer } from './reducers/sessionReducer';
import { tagReducer } from './reducers/tagReducer';

export const store = configureStore({
  reducer: {
    postReducer,
    sessionReducer,
    tagReducer
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({serializableCheck:false}),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;