import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/store";

interface SessionState {
    sessionToken: string;
    user: any;
    isLoggedIn: boolean;
}

const initialState: SessionState = {
    sessionToken: '',
    user: {},
    isLoggedIn: false
}

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setUserSession: (state, action) => {
            state.sessionToken = action.payload?.access_token;
            state.user  = action.payload?.user;
            state.isLoggedIn = action.payload?.access_token ? true : false
        },
        revokeUserSession: (state) => {
            state.sessionToken = ''
            state.user = {}
            state.isLoggedIn = false
        }
    },
    extraReducers: builder => {

    }
})


export const sessionSelector = (state: RootState) => state.sessionReducer;

export const { setUserSession, revokeUserSession } = sessionSlice.actions

export const sessionReducer = sessionSlice.reducer
