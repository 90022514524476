import { FC, useEffect } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

import {
  EditorComposer,
  Editor,
  ToolbarPlugin,
  AlignDropdown,
  BackgroundColorPicker,
  BoldButton,
  CodeFormatButton,
  FloatingLinkEditor,
  FontFamilyDropdown,
  FontSizeDropdown,
  InsertDropdown,
  InsertLinkButton,
  ItalicButton,
  TextColorPicker,
  TextFormatDropdown,
  UnderlineButton,
  Divider
} from 'verbum';
import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";

import { setPostBody } from '../../store/reducers/postReducer'
import { useDispatch } from 'react-redux';


const TextEditor: FC<any> = ({ data }) => {

  const dispatch = useDispatch()

  // useEffect(() => {
  // }, [])

  const onHandleChange: any = () => {
    // store the state to redux store
    const editorContent = document.querySelector('.ContentEditable__root')?.innerHTML

    dispatch(setPostBody(editorContent))
  }


  return (
    <EditorComposer>
      <>
        <Editor hashtagsEnabled emojisEnabled onChange={onHandleChange}>
          <ToolbarPlugin defaultFontSize="20px">
            <FontFamilyDropdown />
            <FontSizeDropdown />
            <Divider />
            <BoldButton />
            <ItalicButton />
            <UnderlineButton />
            <CodeFormatButton />
            <InsertLinkButton />
            <TextColorPicker />
            <BackgroundColorPicker />
            <TextFormatDropdown />
            <Divider />
            <InsertDropdown enablePoll={true} />
            <Divider />
            <AlignDropdown />
          </ToolbarPlugin>
        </Editor>

        {/* <button 
          className="text-opacity-90 group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75" 
          onClick={() => {
          const editorContent = document.querySelector('.ContentEditable__root')?.innerHTML
          console.log('editor content', editorContent)
          submitPost(editorContent)
        }}>
          Submit
        </button> */}
        {/* <ButtonPrimary onClick={submitPost}> Submit </ButtonPrimary> */}
      </>
    </EditorComposer>
  );
};

export default TextEditor;