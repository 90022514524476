import { useState, useEffect, FC } from 'react'

import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";



import { supabase } from 'services/supabase';
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useAppDispatch } from 'store/hooks';
import { setUserSession, revokeUserSession } from 'store/reducers/sessionReducer';

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {

  const [session, setSession] = useState(null)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {

    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      dispatch(setUserSession(session))
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      dispatch(setUserSession(session))
    })

    return () => subscription.unsubscribe()

  }, [])


  if (!session) {
    return (
      <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
        {/* <Helmet>
          <title>Login || Booking React Template</title>
        </Helmet> */}
        <div className="container mb-24 lg:mb-32">
          {/* <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Login
          </h2> */}
          <div className="max-w-md mx-auto space-y-6">
            {/* <div className="grid gap-3">
              {loginSocials.map((item, index) => (
                <a
                  key={index}
                  href={item.href}
                  className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                >
                  <img
                    className="flex-shrink-0"
                    src={item.icon}
                    alt={item.name}
                  />
                  <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                    {item.name}
                  </h3>
                </a>
              ))}
            </div>
            OR
            <div className="relative text-center">
              <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                OR
              </span>
              <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
            </div>

            <form className="grid grid-cols-1 gap-6" action="#" method="post">
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Email address
                </span>
                <Input
                  type="email"
                  placeholder="example@example.com"
                  className="mt-1"
                />
              </label>
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Password
                  <Link to="/forgot-pass" className="text-sm">
                    Forgot password?
                  </Link>
                </span>
                <Input type="password" className="mt-1" />
              </label>
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </form>

           
             */}

            <span className="block text-center font-thin text-gray-500 dark:text-gray-100 mt-5">
              Login now and start creating your own post!
            </span>

            <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} providers={[]} />


          </div>

        </div>
      </div>
    );
  } else {
    navigate('/create')
  }

};

export default PageLogin;