import rightImg from "images/about-page-banner.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      {/* <Helmet>
        <title>About || Booking React Template</title>
      </Helmet> */}

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="What is Tekbrainer?"
          btnText=""
          subHeading={`This blog provides insightful articles, tutorials, and case studies to keep you informed and inspired.

          Whether you're a professional developer, an AI researcher, or just curious about technology, TekBrainer has something for you. Join me as we explore the latest in technology and innovation.
          
          Let's embrace the future of tech together!`}
        />

        <SectionFounder />
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div> */}

        {/* <SectionStatistic /> */}

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageAbout;
