import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
// import { fetchPosts,  } from "api/apiRequests";
import { error } from "console";
import { RootState } from "store/store";

interface PostState {
    isLoading: boolean;
    error: string | undefined;
    postBody: string;
    filename: string;
    fileObject: any;
    posts: Array<any>;
    searchTerm: string;
    searchResults: Array<any>;
}

const initialState: PostState = {
    isLoading: false,
    error: undefined,
    posts: [],
    postBody: '',
    filename: '',
    fileObject: null,
    searchTerm: '',
    searchResults: []
}

// export const fetchCPostsResult: any = createAsyncThunk(
//     'post/all',
//     async () => {
//         // return await fetchPosts s()
//     }
// )

export const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        setPosts: (state, action) => {
            state.posts = action.payload
        },
        setPostBody: (state, action) => {
            state.postBody = action.payload
        },
        setFilename: (state, action) => {
            state.filename = action.payload
        },
        setFileObject: (state, action) => {
            state.fileObject = action.payload
        },
        setSearchResult: (state, action) => {
            state.posts = action.payload
        }
    },
    extraReducers: builder => {
        // builder.addCase(fetchCPostsResult.pending, state => {
        //     state.isLoading = true;
        // });
        // builder.addCase(fetchCPostsResult.fulfilled, (state, action: PayloadAction<Array<any>>) => {
        //     state.isLoading = false;
        //     state.cityCollection = action.payload;
        // });
        // builder.addCase(fetchCPostsResult.rejected, (state, action) => {
        //     state.error = action.error.message;
        //     state.cityCollection = [];
        //     state.isLoading = false;
        // });
    }
})

export const postSelector = (state: RootState) => state.postReducer;

export const { setPostBody, setFilename, setFileObject, setPosts, setSearchResult } = postSlice.actions

export const postReducer = postSlice.reducer