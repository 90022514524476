import React from "react";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import TextEditor from "./TextEditor";

export interface CommonLayoutProps {
    index?: string;
    nextHref?: string;
    backtHref?: string;
    nextBtnText?: string;
    children: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({
    index = "01",
    children,
    nextHref,
    nextBtnText,
    backtHref,
}) => {
    return (
        <div
            className={`px-4 max-w-5xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`} 
        >
            <div className="space-y-11">

                <div className="">{children}</div>

                {/* <div className="flex justify-end space-x-5">
                    <ButtonSecondary>Reset</ButtonSecondary>
                    <ButtonPrimary> Submit </ButtonPrimary>
                </div> */}
            </div>
        </div>
    );
};

export default CommonLayout;
