import React, { useEffect, useState, FC } from 'react'
import { getPosts, getPostsByTags } from 'services/supabase'
import SectionMagazine5 from './SectionMagazine5'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import { Link, useLocation } from 'react-router-dom'
import Heading from 'components/Heading/Heading'
import { capitalizeFirstLetter } from 'utils/helpers'
import ButtonPrimary from 'shared/Button/ButtonPrimary'

const TopicsPerCategory: FC<any> = ({ props }) => {
    const location = useLocation()

    const [posts, setPosts] = useState<any[]>([])

    const tagId = location?.state?.id || 0
    const tagDescription: string = location.pathname.split('/')[2].replace('-', ' ')

    useEffect(() => {
        const fetchData = async () => {
          const postsResponse = await getPostsByTags(tagId)
          if (postsResponse) setPosts(postsResponse);
        }
    
        fetchData()
      }, [])


  const renderNoContent = () => {
    return (
      <div className="flex flex-col items-center justify-center h-full py-20">
        <div className="space-y-4 text-center max-w-md px-4">
          
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">No post related to this topic yet 🙁 </h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            It looks like there are no posts related to the selected topic. Try browsing other topics or check back later for
            new content.
          </p>
          <Link
            to="/"
            className="inline-flex h-9 items-center justify-center rounded-md bg-violet-900 px-4 py-2 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
          >
            Go to Homepage
          </Link>

        </div>
      </div>
    )
  }
  

  return (
    <div className="nc-BlogPage overflow-hidden relative">

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* ======== ALL SECTIONS ======== */}

      {/* ======= START CONTAINER ============= */}
      <div className="container relative">
        
      <header className="text-center max-w-2xl mx-auto my-20">
        <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          <span className="mr-4 text-3xl md:text-4xl leading-none"></span>
          {capitalizeFirstLetter(tagDescription)}
        </h2>
        {posts.length > 0 && <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          250 Likes . 50K Contents
        </span>}
      </header>

        {posts.length === 0 && renderNoContent()}

        {/* <Heading desc="" hasNextPrev>Recommended Stories</Heading> */}
        {/* <div className="border-b border-neutral-200 dark:border-neutral-700 my-5"></div> */}

        <div className="pt-7 pb-16 lg:pb-28">
          <SectionMagazine5 posts={posts.filter((_, i) => i >= 0 && i < 8)} />
        </div>

        {/* <SectionAds /> */}

      </div>
    </div>
  )
}

export default TopicsPerCategory