// TagDropdown.js
import React, { useState } from 'react';

const TagDropdown = ({ options, selectedTags, setSelectedTags }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  return (
    <div className="relative inline-block w-64">
      <div
        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        onClick={toggleDropdown}
      >
        {selectedTags.length === 0 ? 'Select tags' : selectedTags.join(', ')}
      </div>
      {dropdownOpen && (
        <ul className="absolute z-10 w-full bg-white border border-gray-400 rounded shadow-md mt-1">
          {options.map((option) => (
            <li
              key={option}
              className={`px-4 py-2 cursor-pointer hover:bg-gray-200 ${
                selectedTags.includes(option) ? 'bg-gray-200' : ''
              }`}
              onClick={() => handleTagClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TagDropdown;
