import React, { useState } from 'react'
import { supabase, uploadFile } from 'services/supabase';
import { useAppDispatch } from 'store/hooks';
import { setFilename } from 'store/reducers/postReducer';

import { setFileObject } from 'store/reducers/postReducer';

const FileUpload = ({ onHandleUpload, isUploading }) => {
    // const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    // const [uploading, setUploading] = useState(false)
    const dispatch = useAppDispatch()

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        dispatch(setFileObject(selectedFile));
        if (selectedFile) {
            const previewUrl = URL.createObjectURL(selectedFile);
            // update the image preview url
            setPreviewUrl(previewUrl);
          
            // update the image filename in the global state
            dispatch(setFilename(selectedFile.name))
        } else {
            setPreviewUrl(null);
        }
        
    };

    return (
        <div>
            <p className="text-sm">Cover image</p>
            <div className="mt-2">
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                    <div className="space-y-1 text-center">
                        {!previewUrl && <svg
                            className="mx-auto h-12 w-12 text-neutral-400 justify-center"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                        >
                            <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                        </svg>}

                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300 justify-center">
                            <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                            >
                                {!previewUrl && <span>Upload a file</span>}
                                <input
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    className="sr-only"
                                    onChange={handleFileChange}
                                />
                                {previewUrl && <img src={previewUrl} alt="Preview" />}
                            </label>
                            {/* <p className="pl-1">or drag and drop</p> */}
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                            PNG, JPG, GIF up to 10MB
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FileUpload